body,
html {
  margin: 0;
}
main {
  &:not(.login-page) {
    padding-top: 155px;
    padding-bottom: 150px;
    min-height: calc(100vh - 421px);
    @include respond(sm) {
      min-height: auto;
      padding-top: 110px;
    }
  }
  &.login-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .main-header {
      margin: 0;
      max-width: none;
    }
    .full-height {
      height: auto;
    }
  }
}

//--- Containers
.container,
.container-sm,
.container-xs {
  margin: 0 auto;
  padding: 0 15px;
}
.container {
  width: 1140px;
  margin: 0 auto;
  @include respond(md) {
    width: 960px;
  }
  @include respond(sm) {
    width: 720px;
  }
  @include respond(xs) {
    width: 100%;
  }
}
.container-xs {
  max-width: 825px;
}
.container-sm {
  max-width: 930px;
}

//--- Sections
.section {
  padding-top: 50px;
  padding-bottom: 50px;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  @include respond(xs) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

//--- Columns
//[class*="col-"]
//  padding: 15px
.col-1-2 {
  width: 50%;
}
.col-1-3 {
  width: 33.3333%;
}
.col-2-3 {
  width: 66.6666%;
}
.col-sm-1-2 {
  @include respond(sm) {
    width: 50%;
  }
}
.col-sm-full {
  @include respond(sm) {
    width: 100%;
  }
}
.col-xs-full {
  @include respond(xs) {
    width: 100%;
  }
}
.col-xs-auto {
  @include respond(xs) {
    width: auto;
  }
}
