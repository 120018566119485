.item-day {
  border-top: 1px solid rgba($white, 0.3);
  padding: 30px 0;
  @include respond(xs) {
    padding: 20px 0;
  }
  .title-medium,
  .text-smaller {
    text-transform: uppercase;
  }
}
