.table-box {
  overflow: hidden;
  padding: 1px;

  table {
    margin: 0 auto;
    width: 100%;
    border-collapse: collapse;
    @include respond(xs) {
      min-width: 650px;
    }
    &:not(.table-dates) {
      tr {
        background: $grey;
      }
      thead th,
      tr.headlike td {
        border: 1px solid $black;
        padding: 20px;
        @include title;
        @include respond(sm) {
          padding: 15px;
        }
        @include respond(xs) {
          padding: 10px;
        }
      }
      tbody td {
        border: 1px solid $black;
        color: $white;
        @include text-medium;
        padding: 20px;
        @include respond(sm) {
          padding: 15px 30px;
        }
        @include respond(xs) {
          padding: 10px 30px;
        }
        span {
          display: inline-block;
          margin-left: 20px;
        }
      }
    }
    caption {
      @include title-medium;
      padding: 20px;
      background: lighten($grey, 5%);
      @include respond(xs) {
        text-align: left;
        padding-left: 30px;
      }
    }
    tr.course-link {
      cursor: pointer;
      &:hover {
        background: lighten($grey, 3%);
      }
      a {
        color: inherit;
      }
    }
  }
}
