.sort {
  color: rgba($white, 0.6);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.7px;
  position: relative;
  min-width: 200px;

  &,
  &__toggle {
    display: inline-flex;
    align-items: center;
  }
  @include respond(xs) {
    display: flex;
  }
  &__toggle,
  &__toggle i,
  &__toggle span,
  &__dropdown li {
    cursor: pointer;
    transition: color 0.1s ease;
  }
  &__toggle {
    margin-left: 10px;
    color: inherit;
    i {
      font-size: 10px;
      margin-left: 10px;
      display: block;
      transition: transform 0.2s ease;
    }
    &.active i {
      transform: rotate(180deg);
    }
    &:hover {
      i,
      span {
        color: $primary;
      }
    }
  }
  &__dropdown {
    @include posAbsTopRight(calc(100% + 10px), 0);
    background: $grey;
    padding: 10px 15px;
    width: 100%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    display: none;
    z-index: 2;
    li {
      display: block;
      padding: 5px 0;
      label {
        cursor: pointer;
        transition: color 0.2s;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
