//--- Flex

.flex,
.flex-inline {
  flex-wrap: wrap;

  &--middle {
    align-items: center;
  }
  &--top {
    align-items: flex-start;
  }
  &--baseline {
    align-items: baseline;
  }
  &--center {
    justify-content: center;
  }
  &--bottom {
    align-items: flex-end;
  }
  &--no-wrap {
    flex-wrap: nowrap;
  }
  &--grow {
    flex-grow: 1;
  }
  &--no-shrink {
    flex-shrink: 0;
  }
  &--15 {
    margin: -15px;
    .column {
      padding: 15px;
    }
  }
  &--left {
    align-self: flex-start;
    @include respond(xs) {
      align-self: center;
      margin: 0 auto 30px auto;
    }
  }
  &--right {
    margin-left: auto;
    @include respond(xs) {
      margin: 0 auto;
      align-self: center;
    }
  }
  &--sm-wrap {
    @include respond(sm) {
      flex-wrap: wrap;
    }
  }
  &--xs-wrap {
    @include respond(xs) {
      flex-wrap: wrap;
    }
  }
  &--xs-grow {
    @include respond(xs) {
      flex-grow: 1;
    }
  }
}
.flex {
  display: flex;
}
.flex-inline {
  display: inline-flex;
}
.flex-xs {
  @include respond(xs) {
    display: flex;
  }
}
