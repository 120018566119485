.cta-block {
  background: $black;
  border-top: 1px solid rgba($white, 0.3);
  margin-top: 110px;
  @include respond(xs) {
    margin-top: 35px;
  }
  &__content {
    padding: 30px 0;

    p.text-small i {
      width: 145px;
      display: inline-block;
    }
  }
}
