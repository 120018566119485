.item-date {
  border-top: 1px solid rgba($white, 0.3);
  padding: 40px 0;
  &:last-child {
    border-bottom: 1px solid rgba($white, 0.3);
  }
  @include respond(xs) {
    padding: 20px 0;
  }
  &__title {
    padding-right: 27px;
    position: relative;
    display: inline-block;
    transition: all 0.2s;
    i {
      font-size: 12px;
      transition: inherit;
      @include posAbsTopRight(9px, 0);
    }
    &.toggle {
      cursor: pointer;
      &:hover,
      &:hover i {
        color: $primary;
      }
      &.active i {
        transform: rotate(180deg);
      }
    }
  }
  &__dropdown {
    padding: 20px 0 10px;
    display: none;
  }
  .data-col {
    margin-right: 200px;
  }
  .button-col {
    float: right;
    @include respond(sm) {
      display: none;
    }
  }
  .button-col-bottom {
    @include respondAboveSM {
      display: none;
    }
  }
}
