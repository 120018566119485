@keyframes fade-in-nav {
  from {
    opacity: 0;
    transform: scale(1.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-in-scale {
  from {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.4);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-in-top {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes notification {
  from {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes scroll-down {
  from {
    transform: translateY(0);
  }
  25% {
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
  }
} ;
