//--- Accessible visibility
.visuallyhidden {
  @include visuallyhidden;
}

//--- Responsive visibility
.hidden {
  display: none !important;
}
.hidden-sm {
  @include hidden-sm;
}
.hidden-xs {
  @include hidden-xs;
}
.visible-sm {
  @include visible-sm;
}
.visible-xs {
  @include visible-xs;
}

//--- Alignment
.center {
  text-align: center;
  justify-content: center;
}
.center-block {
  margin-left: auto;
  margin-right: auto;
}
.right {
  text-align: right;
  justify-content: right;
}
.float-right {
  float: right;
}
.left-sm {
  @include respond(sm) {
    text-align: left;
    justify-content: left;
  }
}
.left-xs {
  @include respond(xs) {
    text-align: left;
    justify-content: left;
  }
}
.center-xs {
  @include respond(xs) {
    text-align: center;
  }
}

//--- Margins and Paddings
.mg-t-100 {
  margin-top: 100px;
}
.mg-t-70 {
  margin-top: 70px;
}
.mg-t-60 {
  margin-top: 60px;
}
.mg-t-50 {
  margin-top: 50px;
}
.mg-t-40 {
  margin-top: 40px;
}
.mg-t-35 {
  margin-top: 35px;
}
.mg-t-30 {
  margin-top: 30px;
}
.mg-t-25 {
  margin-top: 25px;
}
.mg-t-20 {
  margin-top: 20px;
}
.mg-t-15 {
  margin-top: 15px;
}
.mg-t-10 {
  margin-top: 10px;
}
.mg-b-40 {
  margin-bottom: 40px;
}
.mg-b-25 {
  margin-bottom: 25px;
}
.mg-b-15 {
  margin-bottom: 15px;
}
.mg-b-10 {
  margin-bottom: 10px;
}
.mg-b-50 {
  margin-bottom: 50px;
}
.mg-l-30 {
  margin-left: 30px;
}
.mg-l-xs-0 {
  @include respond(xs) {
    margin-left: 0;
  }
}
.mg-l-xs-15 {
  @include respond(xs) {
    margin-left: 15px;
  }
}
.mg-b-xs-15 {
  @include respond(xs) {
    margin-bottom: 15px;
  }
}
.mg-t-sm-25 {
  @include respond(sm) {
    margin-top: 25px;
  }
}
.mg-t-sm-20 {
  @include respond(sm) {
    margin-top: 20px;
  }
}
.mg-t-xs-70 {
  @include respond(xs) {
    margin-top: 70px;
  }
}
.mg-t-xs-30 {
  @include respond(xs) {
    margin-top: 30px;
  }
}
.mg-t-xs-25 {
  @include respond(xs) {
    margin-top: 25px;
  }
}
.mg-t-xs-20 {
  @include respond(xs) {
    margin-top: 20px;
  }
}
.mg-t-xs-15 {
  @include respond(xs) {
    margin-top: 15px;
  }
}
.mg-auto-h {
  margin: 0 auto;
}
.pd-r-30 {
  padding-right: 30px;
}
.pd-r-15 {
  padding-right: 15px;
}
.pd-b-40 {
  padding-bottom: 40px;
}

//--- Widths/Heights
.mw-780 {
  max-width: 780px;
}
.mw-730 {
  max-width: 730px;
}
.mw-540 {
  max-width: 540px;
}
.mw-480 {
  max-width: 480px;
}
.mw-460 {
  max-width: 460px;
}
.mw-380 {
  max-width: 380px;
}
.mw-sm-full {
  @include respond(sm) {
    max-width: 100%;
  }
}
.mw-95p {
  max-width: 95%;
}
.w-150 {
  max-width: 150px;
}
.full {
  width: 100%;
}

//--- Texts
.upper {
  text-transform: uppercase;
}
.lower {
  text-transform: lowercase;
}

//--- Colors
.bg-grey {
  background: $grey;
}

//--- Font styles
.fs-16 {
  font-size: 16px;
}

//--- Displays
.dp-block {
  display: block;
}
.dp-inline {
  display: inline-block;
}
.dp-sm-block {
  @include respond(sm) {
    display: block;
  }
}
.dp-xs-block {
  @include respond(xs) {
    display: block;
  }
}
