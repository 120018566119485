.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -5px;

  label {
    margin: 5px;
    border: 1px solid rgba($white, 0.4);
    padding: 3.5px 11px;
    display: block;
    color: rgba($white, 0.7);
    font-size: 16px;
    letter-spacing: 0.7px;
    transition: all 0.2s;
    cursor: pointer;
    text-align: center;
    &:hover,
    &.active {
      border-color: $white;
    }
    &:hover {
      color: $white;
    }
    &.active {
      background: $white;
      color: $black;
    }
    @include respond(xs) {
      flex-grow: 1;
    }
  }
}
