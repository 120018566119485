.card-cta {
  padding: 30px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  @include respond(sm) {
    padding: 20px;
  }
  &__text {
    margin-top: 15px;
    color: white;
  }
  &__title {
    color: white;
  }
}
.cards-cta {
  .card-cta {
    background: $primary;

    &:hover,
    &:focus {
      background: lighten($primary, 8%);
    }
  }
}
