.footer {
  background: $black;
  padding-top: 60px;
  @include respond(xs) {
    padding-top: 0;
  }
  & * {
    color: $white;
  }
  &__top,
  &__bottom,
  &__social {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &,
  &__links {
    @include respond(sm) {
      justify-content: center;
      text-align: center;
    }
  }
  &__links,
  &__social,
  &__copyright {
    align-items: center;
  }
  &__top,
  &__bottom {
    &__left {
      flex-grow: 1;
    }
    &__left,
    &__right {
      @include respond(sm) {
        width: 100%;
      }
    }
  }
  &__logo.general a {
    display: block;
    width: 120px;
    @include respond(sm) {
      margin: 0 auto;
      width: 120px;
    }
  }
  &__logo.training {
    display: inline;
    a {
      display: inline-block;
      width: 240px;
      @include respond(sm) {
        margin: 0 auto;
      }
    }
    p {
      display: inline-block;
      vertical-align: text-bottom;
      margin-top: 5px;
      @include respondAboveXS {
        margin: 0 0 0 30px;
        padding-bottom: 4px;
      }
    }
  }
  &__links {
    margin: -15px;
    @include respond(sm) {
      margin: 60px 0 60px;
      columns: 1 !important;
    }
    li {
      margin: 15px;
      &:first-child {
        margin-top: 0;
      }
      @include respond(sm) {
        width: 100%;
        margin: 0;
        &:nth-child(n + 2) {
          margin-top: 23px;
        }
      }
    }
    a,
    .btn-plain {
      color: $white;
      font-size: 16px;
      line-height: 1.5;
      @include respond(sm) {
        font-size: 22px;
      }
      &:hover,
      &:focus {
        color: $red;
        text-decoration: underline;
      }
    }
    &.one-columns {
      columns: 1;
    }
    &.two-columns {
      columns: 2;
    }
    &.three-columns {
      columns: 3;
    }
  }
  &__bottom {
    border-top: 1px solid rgba($white, 0.3);
    margin-top: 40px;
    padding: 30px 0;
    @include respond(sm) {
      border: none;
      margin-top: 0;
      padding: 0;
    }
    a {
      font-size: 16px;
      color: $link-color;
      &:hover,
      &:focus {
        color: $link-color-active;
        text-decoration: underline;
      }
    }
    &__right {
      @include respond(sm) {
        order: -1;
        margin-bottom: 60px;
      }
    }
  }
  &__copyright {
    font-size: 16px;
    font-weight: 400;
    @include respond(sm) {
      padding: 20px 0;
      border-top: 1px solid rgba($white, 0.3);
      margin: 0 -15px;
      font-size: 13px;
    }
  }
  &__social {
    li {
      &:nth-child(n + 2) {
        margin-left: 50px;
      }
      a {
        font-size: 16px;
        color: $link-color;
        &:hover {
          color: $link-color-active;
          text-decoration: underline;
        }
      }
      @include respond(sm) {
        width: 100%;
        &:nth-child(n + 2) {
          margin-left: 0;
          margin-top: 24px;
        }
        a {
          font-size: 22px;
          letter-spacing: 1px;
        }
      }
    }
    @include respond(xs) {
      margin-top: 40px;
    }
  }
}
