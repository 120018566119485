.date-nav {
  border: 1px solid rgba($white, 0.3);
  padding: 15px;
  justify-content: center;
  background: $black-light;
  margin: -1px 1px;

  & * {
    color: $white;
  }
  &,
  &__item {
    display: flex;
    align-items: center;
  }
  &__item:nth-child(n + 2) {
    margin-left: 60px;
    @include respond(xs) {
      margin-left: 30px;
    }
  }
  &__text {
    font-size: 12px;
    font-weight: 500;
    margin: 0 5px;
  }
  &__control {
    padding: 5px;
    cursor: pointer;
    i {
      font-size: 10px;
      display: block;
      opacity: 0.7;
      transition: all 0.3s;
    }
    &:hover i {
      opacity: 1;
    }
  }
}
