.list-inline {
  margin: -5px -15px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  @include respond(xs) {
    display: flex;
  }
  li {
    @include text-small;
    padding: 5px 15px;
    span {
      display: inline-block;
    }
    &.red {
      color: $primary;
      span {
        color: $primary;
      }
    }
  }
  @include respond(xs) {
    li {
      width: 100%;
    }
  }
  &--big li {
    @include text-medium;
    &:not([class*='text-']) {
      color: rgba($white, 0.5);
      span {
        color: $white;
      }
    }
  }
}
.list-dot {
  li {
    @include text-medium;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 1.5;
    position: relative;
    display: block;
    &:nth-child(n + 2) {
      margin-top: 10px;
    }
  }
}
