.breadcrumbs {
  margin: -50px 0 100px;
  @include respond(sm) {
    margin: -30px 0 50px;
  }
  &__list {
    margin: -5px;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    li {
      position: relative;
      padding: 5px;
      &:before,
      a,
      span {
        opacity: 0.5;
      }
      a {
        font-size: 16px;
        letter-spacing: 0.7px;
        color: inherit;
        &:hover,
        &:focus {
          opacity: 1;
        }
      }
      &:nth-child(n + 2) {
        margin-left: 25px;
        &:before {
          content: '>';
          @include posAbsTopLeft(50%, -20px);
          transform: translateY(-50%);
        }
      }
    }
  }
}
