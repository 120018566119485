.items-inline {
  display: flex;
  justify-content: center;

  $self: &;

  &__list {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -30px 0;
    @include respond(xs) {
      margin: 0;
    }
  }
  &__item {
    color: $white;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding: 20px;
    text-align: left;
    white-space: nowrap;
    @include respond(sm) {
      width: 50%;
    }
    @include respond(xs) {
      width: 100%;
      padding: 0;
      &:nth-child(n + 2) {
        margin-top: 20px;
      }
    }
    b {
      color: $primary;
      font-size: 28px;
      letter-spacing: 0.8px;
      display: block;
      margin-bottom: 10px;
      @include respond(xs) {
        margin-bottom: 5px;
      }
    }
  }
  &.six #{$self}__list {
    #{$self}__item:nth-last-child(-n + 2) {
      min-width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
