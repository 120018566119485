.gallery {
  margin: -5px;

  &:hover a {
    opacity: 0.6;
  }
  &__item {
    width: 25%;
    padding: 5px;
    @include respond(md) {
      width: 33.3333%;
    }
    @include respond(sm) {
      width: 50%;
    }
    @include respond(xs) {
      width: 100%;
    }
    a {
      display: block;
      transition: opacity 0.2s ease-in-out;
      color: inherit;
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }
}
