// Consent dialog overrides

.consent {
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;

  a {
    color: $link-color;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: $link-color-active;
      text-decoration: none;
    }
  }
}

.consent__title {
  margin-bottom: 5px;
}

.consent__action {
  display: inline-block;
  width: auto;
  border-radius: 0;
}
