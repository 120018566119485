.title-big {
  @include title-big;
}
.title-medium {
  @include title-medium;
}
.title {
  @include title;
}
.title-small {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.4;
}
.title-medium,
.title-small {
  span {
    color: $primary !important;
  }
}
.title-form {
  font-size: 20px;
  letter-spacing: 0.9px;
  line-height: 1.2;
}
.title-date {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  background: url('../img/icons/calendar.png') no-repeat center/contain;
  width: 60px;
  height: 66px;
  padding-top: 23px;
  color: #4a4a4a !important;
  span {
    display: block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #4a4a4a !important;
  }
  @include respond(xs) {
    font-size: 15px;
    width: 50px;
    height: 50px;
    padding-top: 16px;
    span {
      font-size: 10px;
    }
  }
}
.anchor {
  margin-top: -100px;
  padding-top: 100px;
}
