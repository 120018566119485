.item-img {
  $self: &;

  &__row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include respond(xs) {
      margin: -15px 0;
    }
  }
  &__column {
    .rich-text {
      h3 {
        @include title;
      }
    }
  }
  .col-1-2 {
    @include respond(xs) {
      width: 100%;
      padding: 15px 0 !important;
    }
  }
  &--left .col-1-2 {
    &:first-child {
      padding-right: 60px;
      @include respond(sm) {
        padding-right: 25px;
      }
    }
    &:last-child {
      padding-left: 60px;
      @include respond(sm) {
        padding-left: 25px;
      }
    }
  }
  &--right {
    #{$self}__column {
      &:last-child {
        padding-right: 50px;
        order: -1;
        @include respond(sm) {
          padding-right: 25px;
        }
        @include respond(xs) {
          order: 1;
        }
      }
      &:first-child {
        padding-left: 50px;
        @include respond(sm) {
          padding-left: 25px;
        }
      }
    }
  }
}
