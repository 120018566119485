@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/icomoon.eot?h1c0yz');
  src: url('../fonts/icomoon/icomoon.eot?h1c0yz#iefix')
      format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?h1c0yz') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?h1c0yz') format('woff'),
    url('../fonts/icomoon/icomoon.svg?h1c0yz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: inherit;

  /* Better Font Rendering ===========*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-arrow-down:before {
  content: '\e90a';
}
.icon-arrow-up:before {
  content: '\e90b';
}
.icon-arrow-right:before {
  content: '\e90c';
}
.icon-arrow-left:before {
  content: '\e90d';
}
.icon-instagramm:before {
  content: '\e907';
}
.icon-facebook:before {
  content: '\e908';
}
.icon-youtube:before {
  content: '\e909';
}
.icon-minus:before {
  content: '\e900';
}
.icon-close:before {
  content: '\e901';
}
.icon-plus:before {
  content: '\e902';
}
.icon-search:before {
  content: '\e903';
}
.icon-cart:before {
  content: '\e904';
}
.icon-caret-down:before {
  content: '\e905';
}
.icon-caret-up:before {
  content: '\e90e';
}
.icon-caret-left:before {
  content: '\e90f';
}
.icon-caret-right:before {
  content: '\e910';
}
.icon-alert:before {
  content: '\e906';
}
