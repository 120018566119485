.form {
  &__row {
    &:nth-child(n + 2) {
      margin-top: 40px;
      @include respond(xs) {
        margin-top: 20px;
      }
    }
    .flex {
      margin: 0 -10px;
      [class*='col-'] {
        padding: 0 10px;
        @include respond(xs) {
          width: 100%;
          &:nth-child(n + 2) {
            padding-top: 20px;
          }
        }
      }
    }
  }
  &__block:nth-child(n + 3) {
    margin-top: 45px;
    padding-top: 50px;
    border-top: 1px solid rgba($white, 0.4);
    @include respond(xs) {
      margin-top: 35px;
      padding-top: 40px;
    }
  }
}
.form-section {
  padding-top: 100px;
}
