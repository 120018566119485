.django-form,
.form {
  label,
  span.label-title {
    @include text-medium;
    margin-bottom: 5px;
  }
  label,
  input,
  textarea,
  select {
    display: block;
  }
  input,
  textarea,
  select {
    width: 100%;
  }
  &,
  input,
  span.label-title {
    transition: all 0.2s;
  }
  input,
  textarea,
  select {
    background: lighten($grey, 5%);
    border: 1px solid transparent;
    color: $white;
    font-size: 18px;
    letter-spacing: 0.7px;
    padding: 0 15px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }
  input,
  select {
    height: 58px;
    &[type='file'] {
      padding-top: 12px;
    }
  }
  textarea,
  select[multiple] {
    min-height: 100px;
    padding: 12px 15px;
  }
  select[multiple] option {
    &:nth-child(n + 2) {
      margin-top: 10px;
    }
  }
  textarea {
    min-height: 70px;
    resize: vertical;
  }
  label a {
    color: $link-color;
    text-decoration: underline;
    &:hover,
    &:focus {
      color: $link-color;
      text-decoration: none;
    }
  }
}
label {
  position: relative;
}
input,
textarea,
select {
  @include hide-focus-outline;
  box-shadow: none;
  border: none;
}
input[type='radio'],
input[type='checkbox'] {
  @include visuallyhidden;
}
