.django-form {
  .form-field {
    position: relative;
    &:not(:first-child) {
      margin-top: 30px;
    }
    label {
      margin-bottom: 5px;
      align-items: center;
      span.required {
        margin-left: 2px;
        color: inherit;
      }
    }
    ul.errorlist,
    .help-text {
      margin-top: 5px;
      width: 100%;
      @include text-small;
      &,
      li {
        font-size: 14px;
        letter-spacing: 0.6px;
      }
    }
    &.form-widget-clearablefileinput {
      label {
        width: 100%;
      }
      .file-input {
        display: flex;
        margin-top: 15px;
        align-items: center;
        .btn {
          padding: 13px 29px;
          flex-shrink: 0;
        }
        input {
          height: 55.5px;
          cursor: default;
          &[type='file'] {
            display: none;
          }
        }
        @include respond(xs) {
          flex-wrap: wrap;
          .btn,
          input {
            width: 100%;
          }
          input {
            margin-top: 15px;
            text-align: center;
          }
        }
      }
    }
  }
  ul.errorlist li,
  .has-errors label {
    color: $red;
  }
}
