.card-img {
  width: 100%;
  padding-top: 75%;
  position: relative;
  overflow: hidden;
  display: block;

  &__content,
  &__description {
    @include posAbsZero;
  }
  &__img img {
    height: 100%;
    @include coverImage;
    backface-visibility: hidden;
  }
  &__img img,
  &__description {
    transition: all 0.2s ease-in-out;
  }
  &__description {
    background: rgba(black, 0.4);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
  }
  &__title {
    @include title-medium;
    line-height: 1.5;
    color: white;
  }
  &__date {
    @include title-medium;
    margin-top: 20px;
    color: white;
  }
  &:hover &__img img {
    transform: scale(1.05);
  }
  &:hover &__description {
    background: rgba(black, 0.2);
  }
}
