.num-selector {
  display: inline-block;
  @include respond(xs) {
    width: 100%;
    display: block;
  }
  &__row {
    display: flex;
  }
  input,
  &__control {
    color: $white;
    transition: all 0.2s;
    background: lighten($grey, 5%);
  }
  input {
    width: 48px;
    height: 48px;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    text-align: center;
    padding: 0;
    margin: 0 15px;
    @include respond(xs) {
      margin: 0 15px;
      flex-grow: 1;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  &__control {
    font-size: 20px;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    i {
      transition: color 0.2s;
    }
    &:not(.readonly) {
      cursor: pointer;
      &:hover i {
        color: $primary;
      }
    }
  }
}
