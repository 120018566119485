.full-height {
  position: relative;
  min-height: 300px;
  padding-bottom: 150px;
  .main-header {
    margin: 0;
    max-width: none;
  }
  &,
  &__scroll-down {
    display: flex;
    align-items: center;
  }
  &__scroll-down {
    @include posAbsBottomLeft(40px, 50%);
    transform: translateX(-50%);
    border: 1px solid $white;
    width: 20px;
    height: 30px;
    border-radius: 10px;
    &:before {
      content: '';
      width: 4px;
      height: 4px;
      display: block;
      border-radius: 50%;
      background: $white;
      @include posAbsTopLeft(6px, 7px);
      animation: scroll-down 2s infinite;
    }
  }
  @include respond(sm) {
    height: calc(100vh - 110px);
    padding-bottom: 110px;
  }
  .btn-group {
    &__item {
      padding: 16px 10px;
    }
  }
}
