.collapse-toggle {
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: all 0.3s;
  i {
    transition: inherit;
    &:hover,
    &:hover i {
      color: $primary;
    }
  }
  &.active {
    transform: rotate(180deg);
  }
}
