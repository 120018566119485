.cart-drop,
.search-drop {
  z-index: 1;

  &,
  &__toggle {
    position: relative;
  }
  @include respond(xs) {
    position: static;
  }
  &__toggle {
    color: $white;
    margin-left: 25px;
    transition: opacity 0.1s ease;

    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }
  &__ico {
    font-size: 24px;
    cursor: pointer;
  }
  &__num {
    @include posAbsTopRight(-5px, -7px);
    background: $primary;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-size: 10px;
    color: white;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fade-in-scale 0.9s forwards;
  }
  &__content {
    display: none;
    @include posAbsTopRight(calc(100% + 25px), 0);
    background: $grey;
    width: 450px;
    padding: 25px 30px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    flex-direction: column;
    @include respond(xs) {
      width: calc(100% - 30px);
      padding: 15px;
    }
    &__footer {
      border-top: 1px solid rgba(white, 0.2);
      margin: 15px -30px 0;
      padding: 15px 30px 0;
      p.text-small {
        b,
        span {
          color: $white;
        }
      }
    }
  }
  &__icon img {
    display: inline;
    width: auto;
    &.flag {
      max-height: 16px;
    }
    &.search {
      cursor: pointer;
      max-height: 26px;
    }
  }
  .cards {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overscroll-behavior: contain;
    &::-webkit-scrollbar {
      display: none;
    }
    @include respond(xs) {
      max-height: 100%;
      flex-grow: 1;
    }
  }
}
.cart-drop__content {
  @include respond(xs) {
    @include posFix(64px, 15px, 15px, 15px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
.search-drop__content {
  @include respond(xs) {
    @include posFix(64px, 15px, auto, 15px);
  }
}
