.accordion {
  &__item {
    border-bottom: 1px solid rgba($white, 0.3);

    &__header {
      display: flex;
      position: relative;
      padding: 30px 40px 30px 0;
      cursor: pointer;
      text-transform: uppercase;
      @include respond(xs) {
        cursor: default;
        padding: 20px 25px 20px 0;
      }
      i {
        @include posAbsTopRight(50%, 0);
        transform: translateY(-50%);
        transition: all 0.3s;
        font-size: 12px;
      }
      &:hover i {
        color: $primary;
      }
      &.active i {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    &__body {
      display: none;
    }
  }
}
