.pagination {
  text-align: center;
  margin-top: 40px;
  @include respond(xs) {
    margin-top: 30px;
  }
  &__list {
    display: inline-flex;
    align-items: center;
    margin: 0 -5px;
    @include respondCustom(360) {
      margin: 0;
    }
    li {
      padding: 0 5px;
      @include respondCustom(360) {
        padding: 0 3px;
      }
      @include respondCustom(340) {
        padding: 0;
      }
      a {
        color: $white;
        display: block;
        padding: 7px 12px;
        font-size: 19px;
        font-weight: 400;
        letter-spacing: 0.9px;
        i {
          display: block;
          font-size: 13px;
          transition: inherit;
        }
      }
      &.active a {
        background: $primary;
        color: white;
      }
      &:not(.active) {
        a,
        i {
          &:hover,
          &:focus {
            color: $primary;
          }
        }
      }
    }
  }
}
