@font-face {
  font-family: 'Futura';
  src: url('../fonts/futura/Futura.ttf') format('truetype');
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p {
  margin: 0;
  padding: 0;
  &.red {
    color: $red;
  }
}
ul,
ol {
  list-style: none;
}
a {
  text-decoration: none;
  color: $link-color;

  &:hover,
  &:focus {
    color: $link-color-active;
  }
}
