.responsive-object {
  position: relative;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 930px;
}
.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
iframe {
  max-width: 100%;
}
