//--- Accessibility

// Screen reader accessible text, from HTML5 Boilerplate
@mixin visuallyhidden {
  clip: rect(0 0 0 0);
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
}
@mixin non-visuallyhidden {
  clip: auto;
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  white-space: inherit;
}

@mixin hide-focus-outline {
  &:focus:not(:focus-visible) {
    outline: 0;
  }
}

//--- Responsiveness

@mixin respond($resolution) {
  @if $resolution == md {
    @media (max-width: 1199px) {
      @content;
    }
  }

  @if $resolution == sm {
    @media (max-width: 991px) {
      @content;
    }
  }

  @if $resolution == xs {
    @media (max-width: 767px) {
      @content;
    }
  }

  @if $resolution == xxs {
    @media (max-width: 340px) {
      @content;
    }
  }
}
@mixin respondCustom($resolution) {
  @media (max-width: #{$resolution * 1px}) {
    @content;
  }
}
@mixin respondAboveXS {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin respondAboveSM {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin hidden-sm {
  @include respond(sm) {
    display: none !important;
  }
}
@mixin hidden-xs {
  @include respond(xs) {
    display: none !important;
  }
}
@mixin visible-sm($display: block) {
  display: none;
  @include respond(sm) {
    display: $display !important;
  }
}
@mixin visible-xs($display: block) {
  display: none;
  @include respond(xs) {
    display: $display !important;
  }
}

//--- Positions

@mixin posAbs($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin posAbsZero {
  @include posAbs(0, 0, 0, 0);
}
@mixin posAbsTopRight($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}
@mixin posAbsTopLeft($top, $left) {
  position: absolute;
  top: $top;
  left: $left;
}
@mixin posAbsBottomRight($bottom, $right) {
  position: absolute;
  bottom: $bottom;
  right: $right;
}
@mixin posAbsBottomLeft($bottom, $left) {
  position: absolute;
  bottom: $bottom;
  left: $left;
}
@mixin posFix($top, $right, $bottom, $left) {
  position: fixed;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin posFixTopLeft($top, $left) {
  position: fixed;
  top: $top;
  left: $left;
}
@mixin posFixTopRight($top, $right) {
  position: fixed;
  top: $top;
  right: $right;
}
@mixin posFixBottomLeft($bottom, $left) {
  position: fixed;
  bottom: $bottom;
  left: $left;
}
@mixin posFixBottomRight($bottom, $right) {
  position: fixed;
  bottom: $bottom;
  right: $right;
}
@mixin posFixZero {
  @include posFix(0, 0, 0, 0);
}
@mixin posBack {
  position: relative !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
}

//--- Cover image

@mixin coverImage {
  @include posAbsZero;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

//--- Typography
@mixin title-big {
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Futura', sans-serif;
  @include respond(xs) {
    font-size: 32px;
    letter-spacing: 0.5px;
  }
}
@mixin title-medium {
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0.7px;
  @include respond(xs) {
    font-size: 18px;
  }
}
@mixin title {
  font-family: 'Futura', sans-serif;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
}
@mixin text-medium {
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.7px;
  //@include respond(xs)
  //  font-size: 14px

  &--danger,
  &--success,
  &--warning {
    &:before {
      content: '';
      margin-right: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      bottom: 1.5px;
      @include respond(xs) {
        bottom: 0;
      }
    }
  }
  &--success:before {
    background: $green;
  }
  &--danger:before {
    background: $red;
  }
  &--warning:before {
    background: orange;
  }
}
@mixin text-small {
  &,
  i {
    font-size: 15px;
    color: rgba($white, 0.7);
    letter-spacing: 0.75px;
    font-style: normal;
  }
  b {
    color: $white;
  }
  @include respond(xs) {
    &:not(.total) b {
      font-size: 20px;
    }
  }
}
@mixin text-smaller {
  color: rgba($white, 0.6);
  text-align: left;
  line-height: 1.5;
  font-size: 14px;
  letter-spacing: 0.6px;
}

//--- Checkboxes and radios

@mixin check-rad {
  cursor: pointer;
  padding-left: 37px;
  margin: 0;
  &:before,
  &:after {
    position: absolute;
    cursor: pointer;
  }
  &:before {
    content: '';
    display: block;
    width: 22px;
    height: 22px;
    background: lighten($grey, 5%);
    top: 1px;
    left: 0;
  }
  &:after {
    opacity: 0;
    transform: scale(0);
  }
  &.active:after {
    opacity: 1;
    transform: scale(1);
  }
  &:hover {
    &,
    span.label-title {
      color: $white;
    }
  }
}
@mixin check-rad-focus {
  &:before {
    outline: 2px dotted $primary;
  }
}
@mixin check-rad-focus-hidden {
  &:before {
    outline: 0;
  }
}
@mixin radio {
  &:before,
  &:after {
    border-radius: 50%;
  }
  &:before {
    box-shadow: 0 0 0 1px $white;
  }
  &:after {
    content: '';
    width: 10px;
    height: 10px;
    background: $primary;
    left: 6px;
    top: 7px;
  }
}
@mixin checkbox {
  &:after {
    opacity: 0;
    transform: scale(0);
    content: url('../img/icons/check-prime.svg');
    top: -1px;
    left: 4px;
  }
}
