.item-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  text-align: center;

  &__ico {
    align-items: center;
    justify-content: center;
    width: 120px;
    svg {
      width: 120px;
      height: 120px;
    }
    i {
      font-size: 22px;
    }
  }
  &__title,
  &__btn {
    margin-top: 50px;
    @include respond(sm) {
      margin-top: 20px;
    }
  }
  &__btn + &__btn {
    margin-top: 20px;
  }
  &__text {
    margin-top: 15px;
    flex-grow: 1;
  }
  &__btn {
    .btn {
      font-size: 18px;
    }
    @include respond(xs) {
      width: 100%;
    }
  }
  path {
    fill: $white;
  }
}
.items-cta .col-sm-full:not(:first-child) .item-cta__ico {
  @include respond(sm) {
    margin-top: 30px;
  }
  @include respond(xs) {
    margin-top: 0;
  }
}
