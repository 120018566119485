$black: white;
$white: black;
$grey: #d8d8d8;
$grey-light: #202020;
$black-light: #f4f4f4;
$link-color: $primary;

body {
  background: white;
  color: $white;
}
.logo {
  svg,
  path {
    fill: black;
  }
}
