.cards {
  .card,
  .card-sm {
    &:nth-child(n + 2) {
      margin-top: 20px;
    }
  }
}
.card {
  padding: 30px;
  @include respond(sm) {
    padding: 30px;
  }
  &--with-remove {
    padding: 30px 60px 30px 30px;
  }
  &__remove-ico {
    @include posAbsTopRight(50%, 30px);
  }
  &__remove-text {
    padding-top: 30px;
    margin-top: 30px;
  }
}
.card-sm {
  padding: 20px;
  @include respond(sm) {
    padding: 20px;
  }
  &--with-remove {
    padding: 20px 50px 20px 20px;
  }
  &__remove-ico {
    @include posAbsTopRight(50%, 20px);
  }
  &__remove-text {
    margin-top: 25px;
    padding-top: 15px;
  }
}
.card-black {
  background: $black;
}
.card,
.card-sm {
  position: relative;
  display: block;
  @include respond(xs) {
    padding: 20px;
  }
  &:not(.card-black) {
    background: $grey;
  }
  &__remove-ico {
    color: $white;
    font-size: 14px;
    transform: translateY(-50%);
    transition: opacity 0.2s ease;
    opacity: 0.5;
    cursor: pointer;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
  &__remove-text {
    border-top: 1px solid rgba($white, 0.2);
    text-align: center;
    cursor: pointer;
    @include respond(xs) {
      margin-top: 20px;
      padding-top: 20px;
    }
  }
}
a.card,
a.card-sm {
  color: inherit;
  &:not(.card-black):hover,
  &:not(.card-black):focus {
    background: lighten($grey, 3%);
  }
}
.card,
.card-sm,
.card-ctaw {
  a {
    color: inherit;
  }
  .list-inline {
    b {
      color: $white;
    }
  }
}
.card,
.card-sm,
.card-cta,
.card-drop {
  a:hover,
  a:focus {
    color: $primary;
  }
}
