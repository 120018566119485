.rich-text {
  h2,
  h3,
  p,
  ul,
  ol {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  h2,
  p,
  ul,
  ol {
    margin-bottom: 15px;
    @include respond(xs) {
      margin-bottom: 20px;
    }
  }
  h3 {
    margin-bottom: 15px;
    margin-top: 40px;
    @include title-medium;
    @include respond(xs) {
      margin-bottom: 10px;
      margin-top: 30px;
    }
  }
  h2 {
    @include title-big;
    margin-top: 60px;
    margin-bottom: 30px;
  }
  p,
  ul,
  ol {
    @include text-medium;
  }
  a {
    color: $link-color !important;
    text-decoration: underline;
    &:hover,
    &:focus {
      color: $link-color-active !important;
      text-decoration: none;
    }
  }
  img {
    width: 400px;
    max-width: 100%;
    height: auto;
    &.left {
      float: left;
      margin: 0 50px 50px 0;
    }
    &.right {
      float: right;
      margin: 0 0 50px 50px;
    }
    @include respond(xs) {
      width: 150px;
      &.left {
        margin: 0 15px 15px 0;
      }
      &.right {
        margin: 0 0 15px 15px;
      }
    }
  }
  ul,
  ol {
    li {
      position: relative;
      margin-bottom: 8px;
      &:nth-child(n + 2) {
        margin-top: 3px;
      }
    }
  }
  ul li {
    &:before {
      content: '•';
      color: $primary;
      margin-right: 5px;
    }
  }
  ol {
    counter-reset: ol-counter;
    li {
      counter-increment: ol-counter;
      &:before {
        content: counter(ol-counter) '. ';
      }
    }
  }
}
#why {
  .rich-text {
    text-align: center;
  }
}
.section > .rich-text {
  max-width: 930px;
  margin: 0 auto;
  padding: 0 15px;
}
