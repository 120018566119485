.tooltip {
  position: relative;
  display: inline-block;
  cursor: help;

  &__toggle {
    transition: all 0.2s;
    color: inherit;
    border-bottom: 1px dashed grey;
  }
  &__content {
    background: $grey;
    padding: 10px;
    @include posAbsBottomLeft(calc(100% + 10px), 50%);
    transform: translateX(-50%);
    display: none;
    width: 250px;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 400;
    line-height: 1.4;
    text-align: left;
    border: 1px dotted grey;
    @include respond(xs) {
      width: 200px;
    }
    &--left,
    &--right {
      transform: translateX(0);
    }
    &--left {
      @include posAbsBottomLeft(calc(100% + 10px), 0);
    }
    &--right {
      @include posAbsBottomLeft(calc(100% + 10px), auto);
      right: 0;
    }
  }
  &:hover &__content {
    display: block;
  }
  &:hover &__toggle {
    color: $primary;
  }
}
