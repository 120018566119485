* {
  box-sizing: border-box;
}
img {
  display: block;
  width: 100%;
  height: auto;
}
body {
  background: $black;
  color: $white;
  font-family: 'Roboto', sans-serif;
}
