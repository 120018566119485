.text-medium {
  @include text-medium;
}
.text-small {
  @include text-small;

  &.total {
    @include respond(xs) {
      padding: 15px;
      text-align: center;
      background: $grey;
    }
  }
}
.text-smaller {
  @include text-smaller;
}
.text-remove {
  color: $primary;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  @include respond(xs) {
    font-size: 14px;
  }
}
.text-bold {
  font-weight: bold;
}
