.tabs {
  margin-top: -15px;
  position: relative;

  $self: &;

  &__list {
    display: flex;
    align-items: center;
    @include respond(xs) {
      margin-right: 10px;
      overflow-x: auto;
    }
    li:not(#{$self}__line) {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.3s;
      &:nth-child(n + 2) {
        margin-left: 50px;
      }
      &:hover {
        color: $primary;
      }
      @include respond(xs) {
        font-size: 13px;
        border-bottom: 5px solid transparent;
        &:nth-child(n + 2) {
          margin-left: 15px;
        }
        &.active {
          border-color: $primary;
        }
      }
      span,
      a {
        display: block;
        position: relative;
        height: 30px;
        margin-bottom: -15px;
        color: $white;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        white-space: nowrap;
        &:hover,
        &:focus {
          color: $primary;
        }
      }
    }
  }
  &__line {
    height: 5px;
    background: $primary;
    display: block;
    position: absolute;
    bottom: -5px;
    transition: all 0.5s ease-in-out;
    @include respond(xs) {
      display: none;
    }
  }
  &--sm {
    display: inline-flex;
    @include respond(xs) {
      display: flex;
    }
    #{$self}__list {
      @include respond(xs) {
        width: 100%;
      }
      li:not(#{$self}__line) a {
        font-size: 14px;
      }
    }
  }
}
.tabs-content {
  margin-top: 30px;
  @include respond(xs) {
    margin-top: 20px;
  }
  display: none;
  animation: fade-in-top 0.5s ease-in-out forwards;
  @include respond(xs) {
    margin-top: 30px;
  }
  &.active {
    display: block;
  }
}
