.django-form {
  .form-field {
    &.form-widget-radioselect,
    &.form-widget-checkboxselectmultiple {
      ul.errorlist,
      .help-text {
        margin-top: 15px;
      }
      ul.errorlist + .help-text {
        margin-top: 3px;
      }
    }
    &.form-widget-radioselect ul li,
    &.form-widget-checkboxinput,
    &.form-widget-checkboxselectmultiple ul li {
      display: flex;
      flex-wrap: wrap;
      label {
        @include check-rad;
      }
      input:focus ~ label {
        @include check-rad-focus;
      }
      input:focus:not(:focus-visible) ~ label {
        @include check-rad-focus-hidden;
      }
    }
    &.form-widget-checkboxinput,
    &.form-widget-checkboxselectmultiple ul li {
      label {
        @include checkbox;
      }
    }
    &.form-widget-checkboxselectmultiple,
    &.form-widget-radioselect {
      & > label {
        margin-bottom: 15px;
      }
      ul li {
        &:nth-child(n + 2) {
          margin-top: 20px;
        }
      }
    }
    &.form-widget-radioselect ul li label {
      @include radio;
    }
  }
}
