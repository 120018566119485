.notifications {
  @include posFixTopLeft(15px, 50%);
  z-index: 10;
  width: 685px;
  animation: notification 0.5s forwards;
  display: block;
  @include respond(sm) {
    width: calc(100% - 30px);
  }
  & * {
    color: white;
  }
  .notification {
    display: flex;
    align-items: center;
    background: $primary;
    padding: 15px 25px;
    margin-bottom: 15px;
    @include respond(sm) {
      width: 100%;
      padding: 15px;
    }
    &__text {
      margin-right: 20px;
      flex-grow: 1;
      a {
        text-decoration: underline;
        &:hover,
        &:focus {
          color: darken(white, 10%);
        }
      }
    }
    &__close {
      font-size: 12px;
      cursor: pointer;
      opacity: 0.8;
      transition: all 0.3s;
      &:hover {
        opacity: 1;
      }
    }
  }
}
