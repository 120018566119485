.card-person {
  background: $grey;
  min-height: 100%;

  &,
  &__description {
    display: flex;
    flex-direction: column;
  }
  &__description,
  &__role {
    flex-grow: 1;
  }
  &__img {
    position: relative;
    padding-top: 75%;
    img {
      width: auto;
      @include coverImage;
    }
  }
  &__description {
    padding: 15px;
    div:nth-child(3) {
      margin-top: 15px;
    }
    & * {
      color: $grey-light;
    }
  }
  &__name {
    font-size: 18px;
  }
  &__role {
    text-transform: uppercase;
    margin-top: 5px;
  }
  &__phone,
  &__email {
    a {
      font-weight: 700;
      letter-spacing: 0.5px;
      &:hover,
      &:focus {
        color: $link-color;
      }
    }
  }
  &__email {
    margin-top: 5px;
    a {
      color: $link-color;
      text-decoration: underline;
      &:hover,
      &:focus {
        color: $link-color-active;
        text-decoration: none;
      }
    }
  }
}
