.dropdown {
  position: relative;

  &__toggle {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    i {
      font-size: 12px;
      margin-left: 10px;
      transition: inherit;
    }
    &:hover {
      &,
      i {
        color: $primary;
      }
    }
    &.active i {
      transform: rotate(180deg);
    }
  }
  &__content {
    @include posAbsTopRight(calc(100% + 15px), 0);
    background: $grey;
    padding: 25px;
    display: none;
    li {
      &:nth-child(n + 2) {
        margin: 15px 0 0;
      }
      a {
        white-space: nowrap;
        color: $white;
        @include respond(xs) {
          color: $white;
        }
      }
    }
  }
}
