.form label {
  transition: all 0.2s;

  &.radio,
  &.checkbox {
    @include check-rad;
    label,
    span.label-title {
      margin: 0 !important;
      cursor: pointer;
    }
    &:nth-child(n + 2) {
      margin-top: 20px;
    }
  }
}
.form label.checkbox {
  @include checkbox;
}
.form label.radio {
  @include radio;
}
