//--- Button group (row with several buttons)
.btn-group {
  align-items: center;
  margin: -10px;
  @include respond(xs) {
    flex-wrap: wrap;
  }
  &__item {
    padding: 10px;
    @include respond(xs) {
      width: 100%;
    }
  }
}

//--- Buttons
button,
input[type='button'],
input[type='submit'] {
  cursor: pointer;
}
.btn,
.cc-btn {
  @include hide-focus-outline;
  transition: background-color 0.2s ease, color 0.2s ease;
  box-shadow: none;
  color: $white;
  padding: 14px 29px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: block;
  cursor: pointer;
  text-align: center;
  font-size: 22px;
  border: 2px solid $primary;

  @include respond(xs) {
    letter-spacing: 1.5px;
    width: 100%;
  }
  &--outlined {
    background: $black;
    &:hover,
    &:focus {
      background: lighten-darken($black, 15%, 10%);
      color: $white;
    }
  }
  &--primary {
    background: $primary;
    color: white;
    &:hover,
    &:focus {
      background: lighten($primary, 10%);
      border-color: lighten($primary, 10%);
      color: white;
    }
  }
  &--full {
    width: 100%;
  }
  &--small {
    padding: 8px 12px;
    font-size: 16px;
  }
  &[disabled='disabled'] {
    cursor: default;
    background: $grey;
    border: 2px solid $grey;
  }
}
.btn-fixed {
  width: 94px;
  z-index: 1;
  @include posFixBottomLeft(10px, 15px);
}

// Semantically a button but doesn't have any styles to indicate it
@mixin btn-neutral {
  height: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background: transparent !important;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-transform: inherit;
  letter-spacing: normal;
  vertical-align: baseline;
}
.btn-neutral {
  @include btn-neutral;

  .no-js & {
    cursor: default;
  }
}

@mixin btn-plain {
  @include btn-neutral;

  color: $red;
  cursor: pointer;

  &:hover,
  &:focus {
    color: lighten($red, 5%);
  }
}
.btn-plain {
  @include btn-plain;

  .no-js & {
    cursor: default;
  }
}
