label.search {
  input {
    padding: 0 160px 0 15px;
    @include respond(xs) {
      padding: 0 15px;
    }
  }
  button {
    padding: 13px 29px;
  }
  i.icon-search {
    @include posAbsTopLeft(21px, 15px);
  }
  button {
    @include posAbsTopRight(50%, 0);
    transform: translateY(-50%);
    @include respond(xs) {
      @include posBack;
      margin-top: 15px;
      width: 100%;
    }
  }
}
