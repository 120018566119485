.header {
  padding: 15px 0;
  background: $black;
  position: relative;
  @include posFixTopLeft(0, 0);
  width: 100%;
  z-index: 2;

  &__row,
  &__links {
    display: flex;
    align-items: center;
  }
  &__logo,
  &__menu-ico {
    z-index: 3;
  }
  &__logo {
    a {
      display: block;
      width: 70px;
    }
    @include respond(sm) {
      flex-grow: 1;
      a {
        width: 50px;
      }
    }
  }
  &__nav {
    flex-grow: 1;
    @include respond(sm) {
      @include posFixZero;
      width: 100%;
      background: $black;
      z-index: 2;
      display: none;
      align-items: center;
      justify-content: center;
      padding: 60px 15px;
      animation: fade-in-nav 0.3s forwards;
    }
    @include respondAboveSM {
      display: block !important;
    }
  }
  &__links {
    text-align: center;
    justify-content: center;
    @include respond(sm) {
      display: block;
      max-height: 100%;
      overflow-y: auto;
    }
    > li {
      &:nth-child(n + 2) {
        margin-left: 40px;
        @include respond(sm) {
          margin: 20px 0 0;
        }
      }
    }
    a,
    button {
      color: $white;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.7px;
      text-transform: uppercase;
      transition: color 0.1s ease;
      &:hover,
      &:focus {
        color: $link-color;
      }
      @include respond(sm) {
        font-size: 32px;
        font-weight: 700;
      }
    }
    .dropdown__content {
      @include respond(sm) {
        @include posBack;
        padding: 0;
        background: none;
        li {
          margin-top: 15px;
        }
        a {
          white-space: normal;
          font-size: 24px;
        }
      }
    }
  }
  &__menu-ico {
    @include visible-sm(inline-block);
    position: relative;
    height: 17px;
    width: 17px;
    transition: all 0.3s;
    margin-left: 30px;
    span {
      &,
      &:before,
      &:after {
        content: '';
        width: 17px;
        height: 2px;
        background: $white;
        display: inline-block;
        position: absolute;
        top: 9px;
        transition: all 0.5s;
        left: 0;
      }
      &:before {
        top: 7px;
      }
      &:after {
        top: -7px;
      }
    }
    &.active span {
      & {
        transform: rotate(45deg);
      }
      &:before {
        top: 0;
        transform: rotate(-90deg);
      }
      &:after {
        opacity: 0;
      }
    }
  }
  &__lang-link {
    transition: opacity 0.1s ease;

    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }
}
