.table-dates {
  border-collapse: collapse;
  padding: 0;

  th,
  td,
  &__item {
    color: white;
    span.time {
      color: white;
      font-weight: normal;
    }
  }
  thead tr th {
    padding: 15px;
    background: $grey;
    font-size: 12px;
    span {
      color: inherit;
    }
    &:first-child {
      border-left: 1px solid rgba($white, 0.3);
    }
    &:last-child {
      border-right: 1px solid rgba($white, 0.3);
    }
  }
  tbody tr td {
    position: relative;
    border: 1px solid rgba($white, 0.3);
    padding: 35px 10px 10px;
    width: calc(100% / 7);
    vertical-align: top;
    &.empty {
      background: url('../img/empty-date.png') center;
      background-size: 35px;
      opacity: 0.8;
      // background: rgba($grey, .7)
    }
  }
  &__day {
    font-size: 14px;
    @include posAbsTopLeft(10px, 10px);
  }
  &__item {
    padding: 5px;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    background: $primary;
    display: block;
    &:nth-child(n + 2) {
      margin-top: 5px;
    }
    &:hover {
      background: lighten($primary, 12%);
    }
  }
}
.table-monthly tbody tr td {
  height: 158px;
  @include respond(md) {
    height: 133px;
  }
  @include respond(sm) {
    height: 98px;
  }
  @include respond(xs) {
    height: auto;
  }
}
