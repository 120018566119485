.main-header,
.banner {
  text-align: center;
  p {
    margin: 15px auto 0;
  }
}
.main-header {
  max-width: 930px;
  margin: 0 auto 75px;
  @include respond(xs) {
    max-width: 100%;
    margin-bottom: 70px;
  }
  &__buttons .btn-group {
    width: 100%;
    @include respond(sm) {
      width: auto;
    }
    &__item {
      min-width: 33.3333%;
      @include respond(sm) {
        width: 100%;
      }
    }
  }
}
.banner {
  padding: 100px 0;
  background: $grey;
  display: flex;
  align-items: center;
  justify-content: center;

  @include respond(xs) {
    padding: 50px 15px;
  }
}
.cta {
  padding: 100px 0;
  @include respond(xs) {
    padding: 50px 0;
  }
}
